<template>
  <v-card class="shadowCard pa-2">
    <b>Actividades</b>    
    <br/>
    {{ actividades.length }}
    <br/>
    <br/>
    <b>Realizadas</b>     
    <br/>
    {{ actividades.filter( el => el.cumplio == 1 ).length }}
    <v-progress-linear
      :value="(actividades.filter( el => el.cumplio == 1 ).length / actividades.length ) * 100 "
      color="deep-purple accent-4"
    ></v-progress-linear>
    <br/>
    <b>Sin realizar</b>   
    <br/>
    {{ actividades.filter( el => !el.cumplio ).length }}
    <v-progress-linear
      :value="(actividades.filter( el => !el.cumplio == 1 ).length / actividades.length ) * 100 "
      color="primary"
    ></v-progress-linear>
    <br/>
    <b>% Cumplimiento</b> 
    <br/>
    {{ actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 }}
    <v-progress-linear
      :value="actividades.filter( el => el.cumplio == 1 ).length ? ( (actividades.filter( el => el.cumplio == 1 ).length / actividades.length)*100 ).toFixed(1) : 0 "
      :color="colorCumplimiento"
    ></v-progress-linear>

    <span class="text-h3 mt-2">{{ emojisCalifica }}</span>
  </v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'

  export default {

    props: ['actividades'],

    data: () => ({

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      colorCumplimiento( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return 'red'
        }else if( cumplimiento <= 90 ){
          return 'orange'
        }else{
          return 'green'
        }
      },


      emojisCalifica( ){

        let cumplimiento = this.actividades.filter( el => el.cumplio == 1 ).length ? ( (this.actividades.filter( el => el.cumplio == 1 ).length / this.actividades.length)*100 ) : 0 

        if( cumplimiento <= 33 ){
          return '😰'
        }else if( cumplimiento <= 90 ){
          return '🫠'
        }else{
          return '🤩'
        }
      },

    },

    watch: {},

    async created () {},
    

    methods: {},
  }
</script>


